<template>
  <div>
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
            <new-trade @created="getBalance" />
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">All Transaction</h4>
              </div>
              <div class="card-body">
                <template v-if="balance > 0">
                  <div>
                    <span>Available Balance | <small v-if="deposits.length">expires {{ expiryDate }}</small></span>
                    <h1 class="text-primary">${{ balance }}</h1>
                    <div class="row justify-content-center my-4">
                      <a href="verify"
                        ><button class="btn btn-primary">DEPOSIT</button></a
                      >
                    </div>
                  </div>
                </template>
                <div v-else class="table-responsive">
                  <table class="table table-striped mb-0 table-responsive-sm">
                    <tbody>
                      <br /><br /><br />
                      <h5 align="center" style="color: grey">
                        You currently have no funds. Click the button below to
                        get started
                      </h5>
                      <br /><br />
                      <p align="center">
                        <a href="verify"
                          ><button class="btn btn-primary">DEPOSIT</button></a
                        >
                      </p>
                      <br /><br /><br />
                      <!-- </tr> -->
                    </tbody>
                  </table>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Important Information</h4>
                  </div>
                  <div class="card-body">
                    <div class="important-info">
                      <ul>
                        <li>
                          <i class="mdi mdi-checkbox-blank-circle"></i>
                          For security reasons, Getcoins process withdrawals by
                          review once a day. For more information in this
                          policy. Please see our security/account information
                          page.
                        </li>
                        <li>
                          <i class="mdi mdi-checkbox-blank-circle"></i>
                          Submit your deposits by 14:00 EST to be included on
                          the same day.
                        </li>
                        <li>
                          <i class="mdi mdi-checkbox-blank-circle"></i>
                          Any deposits that have not been used for a trade within 48 hours will be returned to their original bank account.
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import NewTrade from '@/components/NewTrade.vue';

export default {
  components: { NewTrade },
  data: () => ({
    balance: 0,
    deposits: [],
  }),
  computed: {
    expiryDate() {
      if (this.deposits.length > 0) {
        const lastDeposit = [...this.deposits].sort((a, b) => b.id - a.id)[0];
        const date = new Date(lastDeposit.created_at);
        date.setHours(48);
        return date.toDateString();
      }
      return new Date().toDateString();
    },
    fiatBuy() {
      if (this.deposits.length > 0) {
        const fiatDeposits = this.deposits.filter((el) => el.currency_fiat.toLowerCase() === 'usd');
        return fiatDeposits[fiatDeposits.length - 1].amount_fiat;
      }
      return 0;
    },
    btcBuy() {
      if (this.deposits.length > 0) {
        const btcDeposits = this.deposits.filter((el) => el.currency_fiat.toLowerCase() !== 'usd');
        return btcDeposits[btcDeposits.length - 1].amount_fiat;
      }
      return 0;
    },
  },
  mounted() {
    this.getBalance();
    this.getDeposits();
  },
  methods: {
    getBalance() {
      api.balance().then((res) => {
        this.balance = res;
      });
    },
    getDeposits() {
      api.deposit.read().then((res) => {
        console.log(res);
        this.deposits = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-responsive-sm {
  max-width: 100%;
  min-width: unset;
}
</style>
